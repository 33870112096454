import React, { useEffect } from "react";
import { useSelector } from "react-redux";

function useVerifyPermissions() {
  const { auth } = useSelector((state) => state);
  const currentUrl = window.location.href;

  const deniedRoutesSac = ["configuracao", "usuarios"];
  useEffect(() => {
    if (!auth) return;
    if (!auth.token) return;
    if (auth?.data?.user?.roles[0]?.name !== "SAC") return;
    for (let i = 0; deniedRoutesSac.length > i; i++) {
      if (currentUrl.includes(deniedRoutesSac[i])) {
        window.location.href = "/dashboard";
        console.log("currentUrl", currentUrl);
      }
    }
  }, [auth, currentUrl]);

  return {};
}

export default useVerifyPermissions;
