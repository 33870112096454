import React, { useEffect } from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { useSelector } from "react-redux";
import {
  Skeleton,
  Typography,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import JumboCardQuick from "@jumbo/components/JumboCardQuick";
import JumboContent from "@jumbo/components/JumboContent";
import JumboDdMenu from "@jumbo/components/JumboDdMenu";
import ScheduleItem from "./components/ScheduleItem";
import { FaFileExcel, FaFilePdf } from "react-icons/fa";
import { useParams } from "react-router-dom";
import api from "../../services/config";
import ModalAlerta from "app/components/ModalAlert";
import { socketTicket } from "app/services/socket";
import LogTable from "./components/LogTable";

const DetalhesChamados = () => {
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);

  const [currentTab, setCurrentTab] = useState(0);

  const [atual, setAtual] = useState({});
  const [chamado, setChamado] = useState({});
  const [index, setIndex] = useState(0);

  const [action, setAction] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [message, setMessage] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [previousServiceOrders, setPreviousServiceOrders] = useState([]);
  const { id } = useParams();

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);

    setIndex(newValue);

    if (newValue == 0) {
      setChamado(atual);
    } else {
      const chamadosAnteriores = previousServiceOrders;
      setChamado(chamadosAnteriores[newValue - 1]);
    }
  };

  const { setActiveLayout } = useJumboApp();
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  function handleSelectMenuItem(option) {
    setAction(option.action);
    if (option.action == 1) {
      setMessage("Deseja concluir esse ticket?");
    } else if (option.action == 2) {
      setMessage("Deseja cancelar esse ticket?");
    } else if (option.action === 4)
      setMessage("Deseja retornar esse ticket à fila?");
    else {
      setMessage("Deseja acionar a garantia desse ticket?");
    }

    setShowAlert(true);
  }

  async function fetchChamado() {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/api/v2/dashboard/tecnicos/chamados/${id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      data.ticket.tecnico = data.tecnicoResponsavel;

      console.log("CHAMADO > > > ", data);
      setChamado(data.ticket);
      setAtual(data.ticket);
      setPreviousServiceOrders(data.previousServiceOrders);
    } catch (error) {
      console.log("ERROR > > > ", error);
    }
    setLoading(false);
  }

  React.useEffect(() => {
    fetchChamado();
  }, [id, refresh]);
  useEffect(() => {
    console.log("CHAMADO > > > ", chamado);
  }, [chamado]);
  async function close_ticket() {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `/api/v2/tickets/close_anteriores/${chamado.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      setMessage("Ticket fechado com sucesso!");
    } catch (error) {
      console.log("ERROR > > > ", error);
      setMessage("Erro ao fechar ticket.");
    }
    setShowConfirm(true);
    setUpdating(false);
  }

  async function cancel_ticket() {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `/api/v2/dashboard/cancel_ticket/${chamado.id}`,
        {},
        {
          params: {
            isAdmin: true,
          },
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setMessage("Ticket cancelado com sucesso!");

      socketTicket.emit("admin-cancel-call", chamado);
    } catch (error) {
      console.log("ERROR > > > ", error);
      setMessage("Erro ao cancelar ticket.");
    }
    setUpdating(false);
    setShowConfirm(true);
  }

  async function trigger_guarantee() {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `/api/v2/dashboard/tickets/garantia/${chamado.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setMessage("Garantia de ticket acionada com sucesso!");
    } catch (error) {
      console.log("ERROR > > > ", error);
      setMessage("Erro ao acionar garantia!");
    }
    setUpdating(false);
    setShowConfirm(true);
  }

  async function back_to_queue() {
    setUpdating(true);
    try {
      const { data } = await api.put(
        `/api/v2/dashboard/tickets/reabrir/${chamado.id}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );
      setMessage("Ticket devolvido à fila com sucesso!");
    } catch (error) {
      console.log("ERROR > > > ", error);
      setMessage("Erro ao devolver ticket à fila.");
    }
    setUpdating(false);
    setShowConfirm(true);
  }

  async function downloadExcel() {
    setUpdating(true);
    try {
      const { data } = await api.get("api/v2/dashboard/tickets/export/one", {
        params: {
          id: chamado.id,
        },
        headers: {
          Authorization: `Bearer ${auth.token}`,
        },
        responseType: "blob",
      });

      console.log("DATA EXCEL >>> ", data);

      const blob = new Blob([data], { type: "application/xlsx" });

      const el = document.getElementById("excelfile");
      el.href = URL.createObjectURL(blob);
      el.click();
    } catch (error) {
      console.log("ERROR > > > ", error);
    }
    setUpdating(false);
  }

  async function handleAction() {
    setShowAlert(false);

    switch (action) {
      case 1:
        await close_ticket();
        break;
      case 2:
        await cancel_ticket();
        break;
      case 3:
        await trigger_guarantee();
        break;
      case 4:
        await back_to_queue();
        break;
      default:
        break;
    }
  }

  async function downloadPDF() {
    setUpdating(true);
    try {
      console.log("CHAMADO > > > ", chamado.id);
      const { data } = await api.get(
        `api/v2/dashboard/tickets/pdf/${chamado.id}`,
        {
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
          responseType: "blob",
        }
      );

      const blob = new Blob([data], { type: "application/pdf" });

      const el = document.getElementById("pdffile");
      el.href = URL.createObjectURL(blob);
      el.click();
    } catch (error) {
      console.log(error);
    }
    setUpdating(false);
  }

  const menuItens = (status) => {
    const menus = [
      { title: "Mover para Resolvido", action: 1 },
      { title: "Cancelar Ticket", action: 2 },
      { title: "Acionar Garantia", action: 3 },
      { title: "Devolver o Ticket à Fila", action: 4 },
    ];
    switch (status) {
      case 1:
      case 2:
      case 3:
      case 5:
      case 6:
        if (!chamado.tecnico_id) {
          return [menus[0], menus[1]];
        }
        if (auth.data.user.roles[0].name !== "SAC") {
          return [menus[0], menus[1], menus[3]];
        }
        return [menus[0], menus[1]];
      case 4:
      case 7:
        return [menus[2]];
      case 8:
        return [menus[1]];
      default:
        return [];
    }
  };

  return (
    <>
      <a
        download={`TicketsCompleto-revisado-${chamado.id}.xlsx`}
        style={{ display: "none" }}
        id="excelfile"
      />
      <a
        download={`TicketsCompleto-revisado-${chamado.id}${
          index == 0 ? "" : "-" + index
        }.pdf`}
        style={{ display: "none" }}
        id="pdffile"
      />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={updating}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {loading ? (
        <Stack spacing={1} marginTop={1}>
          <Skeleton
            variant="rectangular"
            style={{ height: "800px", borderRadius: 10 }}
          />
        </Stack>
      ) : (
        <>
          <ModalAlerta
            open={showAlert}
            setOpen={setShowAlert}
            message={message}
            buttons={[
              {
                text: "Sim",
                props: {
                  onClick: handleAction,
                  variant: "contained",
                  color: "success",
                },
              },
              {
                text: "Não",
                props: {
                  onClick: () => setShowAlert(false),
                  variant: "contained",
                  color: "error",
                },
              },
            ]}
          />

          <ModalAlerta
            open={showConfirm}
            setOpen={setShowConfirm}
            message={message}
            buttons={[
              {
                text: "Ok",
                props: {
                  onClick: () => {
                    setAction("");
                    setRefresh((prev) => !prev);
                    setShowConfirm(false);
                    setMessage("");
                  },
                  variant: "contained",
                  color: "error",
                },
              },
            ]}
          />

          <JumboCardQuick noWrapper>
            <JumboContent
              bgColor={["#03ce97", "#02af98"]}
              action={
                <Stack direction="row" alignItems="center">
                  <FaFilePdf
                    onClick={downloadPDF}
                    style={{ cursor: "pointer", marginRight: 10 }}
                    color="#FFF"
                    size={22}
                  />
                  <FaFileExcel
                    onClick={downloadExcel}
                    style={{ cursor: "pointer" }}
                    color="#FFF"
                    size={22}
                  />
                  {auth.data.user.roles[0].name !== "SAC" && (
                    <JumboDdMenu
                      menuItems={menuItens(chamado.ticket_status_id)}
                      onClickCallback={handleSelectMenuItem}
                    />
                  )}
                </Stack>
              }
              sx={{ color: "common.white" }}
            >
              <Stack alignItems={"center"} mt={-2} mb={2}>
                <Typography
                  textAlign={{ xs: "center", md: "left" }}
                  variant={"h1"}
                  color={"common.white"}
                >
                  Detalhes do chamado #{chamado.os}
                </Typography>
              </Stack>

              <Tabs
                value={currentTab}
                onChange={handleChangeTab}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
                indicatorColor="primary"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#FFF",
                  },
                }}
              >
                <Tab
                  label="ÚLTIMO ATENDIMENTO"
                  sx={{
                    color: "#FFF",
                    "&[aria-selected=true]": {
                      color: "#FFF",
                    },
                  }}
                />
                {previousServiceOrders &&
                  previousServiceOrders?.map((atividade, index) => (
                    <Tab
                      label={`Atendimento ${
                        previousServiceOrders.length - index
                      }`}
                      sx={{
                        color: "#FFF",
                        "&[aria-selected=true]": {
                          color: "#FFF",
                        },
                      }}
                    />
                  ))}
              </Tabs>
            </JumboContent>
            <JumboContent sx={{ p: 3 }}>
              <ScheduleItem item={chamado} />
            </JumboContent>
          </JumboCardQuick>

          <JumboCardQuick style={{ marginTop: 15 }} noWrapper>
            <JumboContent bgColor={["#03ce97", "#02af98"]}>
              <Stack alignItems={"left"} mt={-2} mb={2}>
                <Typography
                  textAlign={{ xs: "left", md: "left" }}
                  variant={"h4"}
                  color={"common.white"}
                  style={{ marginTop: 20, padding: 10 }}
                >
                  Log de mudanças do chamado
                </Typography>
              </Stack>
            </JumboContent>
            <JumboContent sx={{ p: 3 }}>
              <LogTable logs={chamado.ticket_logs} />
            </JumboContent>
          </JumboCardQuick>
        </>
      )}
    </>
  );
};

export default DetalhesChamados;
