import Div from "@jumbo/shared/Div";
import { formatDate, formatDate2 } from "@jumbo/utils";
import ChipStatus from "./ChipStatus";

const MapTechnicianCard = ({ item, onClick }) => {
  const columns = [
    {
      key: "id",
      cols: "1",
    },
    {
      label: "Equipamento",
      key: "qrcode",
      cols: "1",
    },
    {
      label: "Empresa",
      key: "empresa",
      cols: "2",
    },
    {
      label: "Solicitante",
      key: "solicitante",
      cols: "1",
    },
    {
      label: "Abertura",
      key: "created",
      cols: "1",
      special: "date",
    },
    {
      label: "Status",
      key: "status",
      cols: "1",
      special: "status",
    },
  ];

  const colNumber = columns.reduce((acc, curr) => acc + parseInt(curr.cols), 0);

  const IndexListingCols = ({ columns, item }) =>
    columns.map((column, index) => {
      if (column.key === "id") {
        return (
          <div
            key={index}
            className="px-5 py-1.5 self-center justify-self-center bg-primary rounded-full flex items-center justify-center"
          >
            <span className="text-white"> {item[column.key]}</span>
          </div>
        );
      }
      let value = item[column.key];

      if (column.special === "status") {
        return (
          <div className="grid" style={{ gridColumn: `span ${column.cols}` }}>
            <span className=" text-gray-400 text-[12px]">{column.label}</span>

            <ChipStatus status={value} />
          </div>
        );
      }

      if (column.special === "date") {
        value = value.split(" ")[0];
      }

      return (
        <div className="grid" style={{ gridColumn: `span ${column.cols}` }}>
          <span className=" text-gray-400 text-[12px]">{column.label}</span>
          <span className=" text-gray-800 text-md"> {value}</span>
        </div>
      );
    });

  return (
    <Div
      className={`bg-white rounded-md shadow-md w-full p-4 grid gap-4 hover:shadow-lg cursor-pointer transition-all duration-200`}
      onClick={onClick}
      style={{
        gridTemplateColumns: `repeat(${colNumber}, minmax(0, 1fr))`,
      }}
    >
      <IndexListingCols item={item} columns={columns} />
    </Div>
  );
};

export default MapTechnicianCard;
