import React, { useState } from "react";
import { InputAdornment, Slider, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { format } from "date-fns";

const DateRangeSlider = () => {
  // Initial dates (e.g., one month ago and today)
  const today = new Date();
  const oneMonthAgo = new Date(today);
  oneMonthAgo.setMonth(today.getMonth() - 1);

  // State for date range
  const [dateRange, setDateRange] = useState([oneMonthAgo, today]);

  // Convert dates to slider values (Unix timestamps in days)
  const minDate = new Date("2020-01-01").getTime() / (1000 * 60 * 60 * 24); // Arbitrary min
  const maxDate = today.getTime() / (1000 * 60 * 60 * 24); // Max is today
  const [sliderValue, setSliderValue] = useState([
    dateRange[0].getTime() / (1000 * 60 * 60 * 24),
    dateRange[1].getTime() / (1000 * 60 * 60 * 24),
  ]);

  // Handle slider change
  const handleSliderChange = (event, newValue) => {
    setSliderValue(newValue);
    setDateRange([
      new Date(newValue[0] * 1000 * 60 * 60 * 24),
      new Date(newValue[1] * 1000 * 60 * 60 * 24),
    ]);
  };

  // Handle date picker changes
  const handleDateChange = (index) => (newDate) => {
    const newDateRange = [...dateRange];
    newDateRange[index] = newDate;

    // Ensure min date isn't after max date, and max doesn't exceed today
    if (index === 0 && newDate > newDateRange[1]) {
      newDateRange[1] = newDate > today ? today : newDate;
    } else if (index === 1 && newDate < newDateRange[0]) {
      newDateRange[0] = newDate;
    } else if (index === 1 && newDate > today) {
      newDateRange[1] = today; // Cap at today
    }

    setDateRange(newDateRange);
    setSliderValue([
      newDateRange[0].getTime() / (1000 * 60 * 60 * 24),
      newDateRange[1].getTime() / (1000 * 60 * 60 * 24),
    ]);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div className="w-[440px] ">
        <div className="flex items-center gap-2">
          {/* Min Date Picker */}
          <DatePicker
            label="Início"
            value={dateRange[0]}
            onChange={handleDateChange(0)}
            maxDate={dateRange[1]}
            slotProps={{
              textField: {
                size: "small",
              },
            }}
            format="dd/MM/yyyy"
            className="w-[150px]"
          />

          <div className="flex-1 mx-1.5 h-[40px] items-center">
            <Slider
              value={sliderValue}
              onChange={handleSliderChange}
              valueLabelDisplay="auto"
              min={minDate}
              max={maxDate} // Max set to today
              valueLabelFormat={(value) =>
                format(new Date(value * 1000 * 60 * 60 * 24), "dd/MM/yyyy")
              }
              aria-labelledby="range-slider"
              className="w-full mt-[5px] "
              sx={{
                "color": "#000000",
                "& .MuiSlider-thumb": {
                  "backgroundColor": "#ffffff",
                  "border": "2px solid #000000",
                  "width": 12,
                  "height": 12,
                  "&.Mui-focusVisible": {
                    boxShadow: "0 0 0 6px rgba(0, 0, 0, 0.16)",
                  },
                  "&:hover": {
                    boxShadow: "0 0 0 6px rgba(0, 0, 0, 0.16)",
                  },
                  "&.Mui-active": {
                    boxShadow: "0 0 0 8px rgba(0, 0, 0, 0.16)",
                    width: 10,
                    height: 10,
                  },
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#000000",
                  height: "0px",
                },
                "& .MuiSlider-rail": {
                  backgroundColor: "#bfbfbf",
                  height: "2px",
                },
              }}
            />
          </div>

          <DatePicker
            label="Fim"
            value={dateRange[1]}
            onChange={handleDateChange(1)}
            minDate={dateRange[0]} // Prevent max < min
            maxDate={today} // Max set to today
            slotProps={{ textField: { size: "small" } }}
            format="dd/MM/yyyy" // Set the display format
            className="w-[150px]" // Tailwind width class
          />
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default DateRangeSlider;
