import React, { useEffect } from "react";
import useBiContext from "../../hooks/BiProvider/useBiContext";
import { useFormikContext } from "formik";

const TagsStatus = () => {
  const { filterStatus, setFilterStatus, statuses } = useBiContext();
  const { values, setFieldValue } = useFormikContext();
  const handleFilterClick = (status) => {
    setFieldValue(
      "status",
      values.status.includes(status.id)
        ? values.status.filter((item) => item !== status.id)
        : [...values.status, status.id]
    );
  };

  return (
    <div className="flex flex-wrap gap-2">
      {statuses.map((status, index) => (
        <div
          key={index}
          onClick={() => handleFilterClick(status)}
          className={` rounded-full px-3 items-center flex cursor-pointer transition-colors duration-200  hover:text-white`}
          style={{
            backgroundColor: values.status.includes(status.id)
              ? status.color
              : "#E9E9E9",
            color: values.status.includes(status.id) ? "white" : "black",
          }}
        >
          <span className="text-center text-sm w-full">{status.name}</span>
        </div>
      ))}
    </div>
  );
};

export default TagsStatus;
