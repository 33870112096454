import React, { useEffect } from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { useSelector } from "react-redux";
import {
  Chip,
  Pagination,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import ProjectsList from "./components/ProjectsList";
import { useParams } from "react-router-dom";
import api from "../../services/config";
import { ticketsStatus } from "app/utils/ticketsStatus";
import ChamadosSkeleton from "./Skeleton";
import JumboSearch from "@jumbo/components/JumboSearch";
import Div from "@jumbo/shared/Div";
import SortIcon from "@mui/icons-material/Sort";
import { history } from "app/redux/store";
import { useNavigate } from "react-router-dom";

const ChamadosTecnicos = () => {
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { type } = useParams();

  const [chamados, setChamados] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [order, setOrder] = useState("asc");
  const [paramsOrder, setParams] = useState({});
  const [continueFetching, setContinueFetching] = useState(true);

  const { setActiveLayout } = useJumboApp();
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  React.useLayoutEffect(() => {
    setTimeout(fetchChamados, loading ? 500 : 0);
  }, [page, type, paramsOrder, order]);

  React.useLayoutEffect(() => {
    setContinueFetching(true);
    setPage(1);
  }, [type]);

  async function fetchChamados() {
    if (!continueFetching) return;

    setLoading(true);
    try {
      let params = {};

      if (type != "todos") {
        params = {
          status: ticketsStatus[type],
        };
      }

      if (paramsOrder.s) {
        params["s"] = paramsOrder.s;
      }

      if (paramsOrder.orderBy) {
        params["orderBy"] = `${paramsOrder.orderBy},${order}`;
      }

      const { data } = await api.get(
        `/api/v2/dashboard/tecnicos/chamados?page=${page}`,
        {
          params,
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      setChamados(data.data);
      setTotalPages(data.last_page);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
    setContinueFetching(true);
  }

  useEffect(() => {
    console.log("aa", type);
  }, [type]);

  const typeData = {
    abertos: {
      label: "Aberto",
      color: "default",
      status: 1,
    },
    tecnico_a_caminho: {
      label: "Técnico a caminho",
      color: "info",
      status: 2,
    },
    tecnico_chegou: {
      label: "Técnico chegou",
      color: "secondary",
      status: 3,
    },
    resolvidos: {
      label: "Resolvido",
      color: "success",
      status: 4,
    },
    pendentes: {
      label: "Pendente",
      color: "warning",
      status: 5,
    },
    orfao: {
      label: "Orfão",
      color: "default",
      status: 6,
    },
    cancelados: {
      label: "Cancelado",
      color: "error",
      status: 7,
    },
    garantia: {
      label: "Garantia",
      color: "warning",
      status: 8,
    },
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function toggleOrder() {
    setOrder(order == "asc" ? "desc" : "asc");
  }
  return (
    <>
      <Div>
        <Stack
          direction={{ xs: "column", md: "row" }}
          flex={1}
          alignItems={{ xs: "flex-start", md: "center" }}
          justifyContent="space-between"
        >
          {type == "todos" ? (
            <Typography variant={"h2"} mb={3}>
              Lista de Chamados Técnicos
            </Typography>
          ) : (
            <Stack direction="row">
              <Typography variant={"h2"} mb={3}>
                Lista de Chamados Técnicos
              </Typography>
              <Chip
                onClick={handleClick}
                label={typeData[type].label}
                style={{ marginLeft: 10 }}
                color={typeData[type].color}
              />

              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <List>
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => navigate(`/chamados/todos`)}>
                      <ListItemText primary="Todos" />
                    </ListItemButton>
                  </ListItem>
                  {Object.keys(typeData).map((t) => {
                    if (t != type) {
                      return (
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => navigate(`/chamados/${t}`)}
                          >
                            <ListItemText primary={typeData[t].label} />
                          </ListItemButton>
                        </ListItem>
                      );
                    } else {
                      return null;
                    }
                  })}
                </List>
              </Popover>
            </Stack>
          )}

          <Stack direction="row" justifyContent="center">
            <Div sx={{ mr: 3 }}>
              <FormControl sx={{ m: 1, minWidth: 250 }} size="small">
                <InputLabel id="demo-select-small">Ordenar por</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={paramsOrder.orderBy}
                  label="Age"
                  onChange={(event) => {
                    setParams({
                      ...paramsOrder,
                      orderBy: event.target.value,
                    });
                  }}
                >
                  <MenuItem value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value="equipamento_nome">Máquina</MenuItem>
                  <MenuItem value="tempo_chamado">Tempo</MenuItem>
                  <MenuItem value="tecnico_id">Técnico</MenuItem>
                  <MenuItem value="created_at">Data de Abertura</MenuItem>
                  <MenuItem value="client">Solicitante</MenuItem>
                  <MenuItem value="empresa">Empresa</MenuItem>
                  <MenuItem value="ocorrencia_reportada">Ocorrência</MenuItem>
                </Select>
              </FormControl>
              <Button sx={{ m: 1 }} variant="outlined" onClick={toggleOrder}>
                <SortIcon
                  sx={{ transform: `rotateX(${order == "asc" ? 0 : 180}deg)` }}
                />
              </Button>
            </Div>

            <JumboSearch
              onChange={(e) => {
                setContinueFetching(false);
                setPage(1);
                setContinueFetching(true);
                setParams({
                  ...paramsOrder,
                  s: e,
                });
              }}
              placeholder="Busque por chamados"
              sx={{
                display: { xs: "none", md: "block" },
                mb: 3,
                flex: 1,
              }}
            />
          </Stack>
        </Stack>
      </Div>

      {loading ? (
        <ChamadosSkeleton />
      ) : (
        <>
          <Stack style={{ marginTop: 10 }}>
            <ProjectsList data={chamados} type={type} />
            <Pagination
              count={totalPages}
              page={page}
              onChange={(event, value) => setPage(value)}
              shape="rounded"
              variant="outlined"
              color="secondary"
            />
          </Stack>
        </>
      )}
    </>
  );
};

export default ChamadosTecnicos;
