import { Button, Card, Stack } from "@mui/material";
import React, { useEffect, useMemo } from "react";
import StandardInput from "./MapaInputs/StandardInput";
import {
  useCidadeFieldQuery,
  useEmpresaFieldQuery,
  useSegmentoFieldQuery,
  useTecnicoResponsavelFieldQuery,
} from "app/pages/Bi/hooks/bi-slice";
import DateRangeSlider from "./DateRangeSlider";
import ContadoresTopo from "./ContadoresTopo";
import SearchInput from "./MapaInputs/SearchInput";
import { Formik, useFormikContext } from "formik";
import TagsStatus from "./TagsStatus";

const SearchForm = () => {
  const {
    data: empresaData,
    isLoading: lEmpresa,
    isFetching: fEmpresa,
  } = useEmpresaFieldQuery();
  const {
    data: segmentoData,
    isLoading: lSegmento,
    isFetching: fSegmento,
  } = useSegmentoFieldQuery();
  const {
    data: regiaoData,
    isLoading: lRegiao,
    isFetching: fRegiao,
  } = useSegmentoFieldQuery();
  const {
    data: cidadeData,
    isLoading: lCidade,
    isFetching: fCidade,
  } = useCidadeFieldQuery();
  const {
    data: tecnicoData,
    isLoading: lTecnico,
    isFetching: fTecnico,
  } = useTecnicoResponsavelFieldQuery();
  const {
    data: solicitanteData,
    isLoading: lSolicitante,
    isFetching: fSolicitante,
  } = useTecnicoResponsavelFieldQuery();

  const empresa = useMemo(
    () => [{ value: "", label: "Selecione..." }, ...(empresaData || [])],
    [empresaData]
  );
  const segmento = useMemo(
    () => [{ value: "", label: "Selecione..." }, ...(segmentoData || [])],
    [segmentoData]
  );
  const solicitante = useMemo(
    () => [{ value: "", label: "Selecione..." }, ...(solicitanteData || [])],
    [solicitanteData]
  );
  const regiao = useMemo(
    () => [{ value: "", label: "Selecione..." }, ...(regiaoData || [])],
    [regiaoData]
  );
  const cidade = useMemo(
    () => [{ value: "", label: "Selecione..." }, ...(cidadeData || [])],
    [cidadeData]
  );

  const tecnico = useMemo(
    () => [{ value: "", label: "Selecione..." }, ...(tecnicoData || [])],
    [tecnicoData]
  );

  const { resetForm, values } = useFormikContext();

  useEffect(() => {
    console.log("cidadeData", cidadeData);
    console.log("empresaData", empresaData);
  }, [cidadeData]);

  return (
    <Card sx={{ padding: 4, marginBottom: 2 }}>
      <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
        <Stack direction="column" spacing={2.5} sx={{ flex: 4 }}>
          <Stack direction="row" spacing={2.5}>
            <SearchInput label="Buscar na observação do cliente..." />
            <SearchInput label="Buscar por matrícula" />
            <Button
              onClick={() =>
                resetForm({
                  values: {
                    empresa: "",
                    segmento: "",
                    regiao: "",
                    cidade: "",
                    tecnico: "",
                    status: [],
                    solicitante: "",
                    data_inicio: "",
                    data_final: "",
                  },
                })
              }
              variant="outlined"
              color="secondary"
              sx={{
                "height": "37px",
                "textTransform": "none",
                "borderRadius": "4px",
                "&:hover": {
                  backgroundColor: "#f5f5f5",
                },
              }}
            >
              Limpar Filtros
            </Button>
          </Stack>
          <Stack direction="row" spacing={2}>
            <StandardInput
              label="Empresa"
              name="empresa"
              options={empresa}
              isFetching={fEmpresa}
              isLoading={lEmpresa}
            />
            <StandardInput
              label="Segmento"
              name="segmento"
              options={segmento}
              isFetching={fSegmento}
              isLoading={lSegmento}
            />
            <StandardInput
              label="Região"
              name="regiao"
              options={regiao}
              isFetching={fRegiao}
              isLoading={lRegiao}
            />
            <StandardInput
              name="cidade"
              label="Cidade"
              options={cidade}
              isFetching={fCidade}
              isLoading={lCidade}
            />
            <StandardInput
              name="tecnico"
              label="Técnico Responsável"
              options={tecnico}
              isFetching={fTecnico}
              isLoading={lTecnico}
            />
            <StandardInput
              name="solicitante"
              label="Solicitante"
              options={solicitante}
              isFetching={fSolicitante}
              isLoading={lSolicitante}
            />
          </Stack>
          <div className="flex gap-4">
            <DateRangeSlider />

            <TagsStatus />
          </div>
        </Stack>
        <ContadoresTopo />
      </Stack>
    </Card>
  );
};

export default SearchForm;
