import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { useSelector } from "react-redux";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import { useState } from "react";
import ProjectsList from "./components/ProjectsList";
import api from "../../services/config";
import QRCodeSkeleton from "./Skeleton";
import Div from "@jumbo/shared/Div";
import JumboSearch from "@jumbo/components/JumboSearch";
import SortIcon from "@mui/icons-material/Sort";
import CloseIcon from "@mui/icons-material/Close";

const QRCode = () => {
  const { auth } = useSelector((state) => state);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("todos");
  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("asc");

  const [listPrint, setListPrint] = useState([1, 2, 3, 4, 5]);

  const { setActiveLayout } = useJumboApp();
  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  function toggleOrder() {
    setOrder(order == "asc" ? "desc" : "asc");
  }

  return (
    <>
      <Div>
        <Stack
          direction="row"
          flex={1}
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant={"h2"} mb={3}>
            QRCodes
          </Typography>

          <Stack direction="row" justifyContent="center" alignItems="center">
            <Div>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small">Status</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={status}
                  label="Status"
                  onChange={(event) => setStatus(event.target.value)}
                >
                  <MenuItem value="todos">Todos</MenuItem>
                  <MenuItem value="impressos">Impressos</MenuItem>
                  <MenuItem value="nao_impressos">Não Impressos</MenuItem>
                </Select>
              </FormControl>
            </Div>

            <Div sx={{ mr: 3 }}>
              <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
                <InputLabel id="demo-select-small">Ordenar por</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={sortBy}
                  label="Age"
                  onChange={(event) => setSortBy(event.target.value)}
                >
                  <MenuItem value="">
                    <em>Selecione...</em>
                  </MenuItem>
                  <MenuItem value="maquina">Máquina</MenuItem>
                  <MenuItem value="status">Status</MenuItem>
                </Select>
              </FormControl>
              <Button sx={{ m: 1 }} variant="outlined" onClick={toggleOrder}>
                <SortIcon
                  sx={{ transform: `rotateX(${order == "asc" ? 0 : 180}deg)` }}
                />
              </Button>
            </Div>

            <JumboSearch
              onChange={() => {}}
              placeholder="Filtrar QRCODE"
              sx={{
                display: { xs: "none", md: "block" },
                /* mb: 3, */
                flex: 1,
              }}
            />
          </Stack>
        </Stack>

        {listPrint.length > 0 && (
          <Stack direction="row">
            <Div sx={{ mr: 1 }}>
              <Button variant="outlined" color="success">
                Imprimir Selecionados ({listPrint.length})
              </Button>
            </Div>

            <Div>
              <Button
                variant="outlined"
                color="error"
                onClick={() => setListPrint([])}
              >
                <CloseIcon color="error" />
              </Button>
            </Div>
          </Stack>
        )}
      </Div>

      {loading ? (
        <QRCodeSkeleton />
      ) : (
        <>
          <Stack style={{ marginTop: 10 }}>
            <ProjectsList data={[{}, {}, {}]} type={""} />
            {/* <Pagination
                                count={totalPages}
                                page={page}
                                onChange={(event, value) => setPage(value)}
                                shape="rounded"
                                variant="outlined"
                                color="secondary"
                            /> */}
          </Stack>
        </>
      )}
    </>
  );
};

export default QRCode;
