import React from "react";
import BiProvider from "./BiProvider";
import MapaTecnicos from "../../MapaTecnicos";

const BiIndex = () => {
  return (
    <BiProvider>
      <MapaTecnicos />
    </BiProvider>
  );
};

export default BiIndex;
