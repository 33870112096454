import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";

const SearchInput = ({ label }) => {
  const [searchTerm, setSearchTerm] = useState("");
  return (
    <TextField
      label={label}
      variant="outlined"
      size="small"
      sx={{ width: "38%" }}
      value={searchTerm}
      onChange={(event) => setSearchTerm(event.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
