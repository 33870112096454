const INITIAL_STATE = {
  token: "",
  data: "",
};

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case "@mifix_login":
      return {
        ...state,
        ...action.payload,
      };
    case "@mifix_logout":
      return null;
    default:
      return state;
  }
}
