import React from "react";
import {
  Backdrop,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { Facebook, Google, Twitter } from "@mui/icons-material";
import Div from "@jumbo/shared/Div";
import { alpha } from "@mui/material/styles";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { ASSET_IMAGES } from "app/utils/constants/paths";
import { getAssetPath } from "app/utils/appHelpers";
import api from "app/services/config";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { login } from "app/redux/modules/auth/action";
import { useFormik } from "formik";
import * as yup from "yup";
import { history } from "app/redux/store";
import { useState } from "react";

const Login2 = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setActiveLayout } = useJumboApp();
  const [loading, setLoading] = useState(false);

  async function handleAuth(dt) {
    setLoading(true);
    try {
      const { data } = await api.post("/api/v2/dashboard/session", dt);

      dispatch(login(data, data.access_token));
      navigate("/dashboard/");
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  }

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.SOLO_PAGE);
  }, []);

  const validationSchema = yup.object({
    email: yup
      .string("Insira seu e-mail")
      .email("Enter a valid email")
      .required("Email é obrigatório"),
    password: yup.string("Senha é obrigatória").required("Senha é obrigatória"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleAuth(values);
    },
  });

  return (
    <Div
      sx={{
        width: 720,
        maxWidth: "100%",
        margin: "auto",
        p: 4,
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Card
        sx={{
          display: "flex",
          minWidth: 0,
          flexDirection: { xs: "column", md: "row" },
        }}
      >
        <CardContent
          sx={{
            flex: "0 1 300px",
            position: "relative",
            background: `#0267a0 url(${getAssetPath(
              `${ASSET_IMAGES}/widgets/coffe.jpg`,
              "640x428"
            )}) no-repeat center`,
            backgroundSize: "cover",

            "&::after": {
              display: "inline-block",
              position: "absolute",
              content: `''`,
              inset: 0,
              backgroundColor: alpha("#0E8EB5", 0.8),
            },
          }}
        >
          <Div
            sx={{
              display: "flex",
              minWidth: 0,
              flex: 1,
              flexDirection: "column",
              color: "common.white",
              position: "relative",
              zIndex: 1,
              height: "100%",
            }}
          >
            <Div sx={{ mt: "auto" }}>
              <Link href="#" underline="none" sx={{ display: "inline-flex" }}>
                <img
                  style={{ width: 120 }}
                  src={`${ASSET_IMAGES}/logo-mifix-white.png`}
                  alt="Jumbo React"
                />
              </Link>
            </Div>
          </Div>
        </CardContent>
        <CardContent sx={{ flex: 1, p: 4 }}>
          <form onSubmit={formik.handleSubmit}>
            <Div sx={{ mt: 1, mb: 3 }}>
              <TextField
                fullWidth
                id="email"
                name="email"
                label="Email"
                defaultValue="admin@mifix.com.br"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Div>
            <Div sx={{ mt: 1, mb: 2 }}>
              <TextField
                fullWidth
                id="password"
                label="Senha"
                name="password"
                type="password"
                defaultValue="123456"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Div>
            <Div sx={{ mb: 2 }}>
              <FormControlLabel control={<Checkbox />} label="Lembrar" />
            </Div>

            <Stack>
              <Button
                style={{ backgroundColor: "#0267a0" }}
                type="submit"
                variant="contained"
                sx={{ mb: 2 }}
              >
                Logar
              </Button>
              <Link
                onClick={() => navigate("recupera-senha")}
                sx={{
                  textAlign: "end",
                  cursor: "pointer",
                }}
              >
                Recuperar Senha
              </Link>
            </Stack>
          </form>
        </CardContent>
      </Card>
    </Div>
  );
};

export default Login2;
