import { baseApiSlice } from "../../../redux/features/baseApi";

export const biSlice = baseApiSlice.injectEndpoints({
  endpoints(builder) {
    return {
      empresaField: builder.query({
        query: () => "/v2/dashboard/empresas/fields",
      }),
      segmentoField: builder.query({
        query: () => "/v2/dashboard/segmentos/fields",
      }),
      regiaoField: builder.query({
        query: () => "/v2/dashboard/regioes/fields",
      }),
      cidadeField: builder.query({
        query: () => "/v2/dashboard/cidades/fields",
      }),
      tecnicoResponsavelField: builder.query({
        query: () => "/v2/dashboard/tecnicos-responsaveis/fields",
      }),
      solicitanteField: builder.query({
        query: () => "/v2/dashboard/solicitantes/fields",
      }),
      biSistema: builder.query({
        query: (values) => {
          let queryStrings = [];

          if (values.solicitante) {
            queryStrings.push(`solicitante=${values.solicitante}`);
          }
          if (values.perPage) {
            queryStrings.push(`perPage=${values.perPage}`);
          }
          // 'solicitante',
          // 'segmento',
          // 'empresa',
          // 'cidade',
          // 'região',
          // 'matricla',
          // 'tecnico',
          // 'status',
          // 'garantia',
          // 'perPage'
          return `/v2/dashboard/bi-sistema?${queryStrings.join("&")}`;
        },
      }),
      biSistemaMarcadoresMapa: builder.query({
        query: (values) => "/v2/dashboard/bi-sistema-marcadores-mapa",
      }),
    };
  },
});

export const {
  useBiSistemaQuery,
  useCidadeFieldQuery,
  useEmpresaFieldQuery,
  useBiSistemaMarcadoresMapaQuery,
  useSegmentoFieldQuery,
  useRegiaoFieldQuery,
  useSolicitanteFieldQuery,
  useTecnicoResponsavelFieldQuery,
} = biSlice;
// GET -> /v2/dashboard/segmentos/fields
// GET -> /v2/dashboard/regioes/fields
// GET -> /v2/dashboard/cidades/fields
// GET -> /v2/dashboard/tecnicos-responsaveis/fields
// GET -> /v2/dashboard/solicitantes/fields
// GET -> /v2/dashboard/bi-sistema
// GET -> /v2/dashboard/bi-sistema-marcadores-mapa
