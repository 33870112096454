import { Stack, IconButton } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { useEffect, useState, useRef } from "react";

const MapComponent = () => {
  const icon = new L.Icon({
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
    shadowSize: [41, 41],
  });

  const [isFullScreen, setIsFullScreen] = useState(false);
  const mapRef = useRef(null);
  const containerRef = useRef(null);

  const toggleFullScreen = () => {
    setIsFullScreen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        isFullScreen &&
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsFullScreen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isFullScreen]);

  useEffect(() => {
    if (mapRef.current) {
      setTimeout(() => {
        mapRef.current.invalidateSize();
      }, 300);
    }
  }, [isFullScreen]);

  return (
    <>
      {isFullScreen && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker opacity
            zIndex: 1299, // Below map but above other content
          }}
        />
      )}

      <Stack
        ref={containerRef}
        style={{
          paddingTop: 27,
          paddingBottom: 30,
          paddingRight: 30,
          width: isFullScreen ? "90%" : "40%",
          height: isFullScreen ? "100vh" : "64vh",
          position: isFullScreen ? "fixed" : "relative",
          top: 0,
          left: isFullScreen ? "5%" : 0,
          zIndex: isFullScreen ? 1300 : "auto",
          transition: "all 0.3s ease",
        }}
      >
        <MapContainer
          center={[51.505, -0.09]}
          zoom={13}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: 10,
          }}
          whenCreated={(map) => {
            mapRef.current = map;
            map.attributionControl.setPrefix("");
          }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <Marker position={[51.505, -0.09]} icon={icon}>
            <Popup>
              A pretty CSS3 popup. <br /> Easily customizable.
            </Popup>
          </Marker>
        </MapContainer>
        <IconButton
          onClick={toggleFullScreen}
          sx={{
            "position": "absolute",
            "top": 35,
            "right": 40,
            "backgroundColor": "#fff",
            "&:hover": { backgroundColor: "rgba(255, 255, 255, 1)" },
            "zIndex": 1301,
          }}
        >
          {isFullScreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      </Stack>
    </>
  );
};

export default MapComponent;
