import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import { useFormikContext } from "formik";
import React from "react";
const StandardInput = ({ label, name, options, isLoading, isFetching }) => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <Autocomplete
      size="small"
      fullWidth
      filterSelectedOptions={true}
      options={options || []}
      getOptionLabel={(option) => (option ? `${option.label}` : undefined)}
      value={
        options?.find((option) => option.value === values[name]) || undefined
      }
      onChange={(_, value) => {
        setFieldValue(name, value ? value.value : undefined);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          name={name}
          label={label}
          sx={{ minWidth: 150, maxWidth: 350 }}
          InputProps={{
            ...params.InputProps,
            style: {
              paddingTop: 0,
              paddingBottom: 0,
              height: 38,
            },
            endAdornment: (
              <React.Fragment>
                {isLoading || isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
};

export default StandardInput;
