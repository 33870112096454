export function login(data, token) {
  return {
    type: "@mifix_login",
    payload: {
      data,
      token,
    },
  };
}

export function logout() {
  return {
    type: "@mifix_logout",
    payload: null,
  };
}
