import React from "react";
import { useJumboApp } from "@jumbo/hooks";
import { LAYOUT_NAMES } from "app/layouts/layouts";
import { useSelector } from "react-redux";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import UsersList from "./components/UsersList";
import api from "../../services/config";
import JumboSearch from "@jumbo/components/JumboSearch";
import UsuariosSkeleton from "./Skeleton";
import { useParams, useSearchParams } from "react-router-dom";
import { history } from "app/redux/store";
import Div from "@jumbo/shared/Div";
import SortIcon from "@mui/icons-material/Sort";
import { useNavigate } from "react-router-dom";

const Usuarios = () => {
  const { auth } = useSelector((state) => state);
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [users, setUsers] = useState([]);

  const [sortBy, setSortBy] = useState("");
  const [order, setOrder] = useState("asc");
  const [status, setStatus] = useState("todos");
  const [paramsOrder, setParams] = useState({});
  const [continueFetching, setContinueFetching] = useState(true);

  function toggleOrder() {
    setOrder(order == "asc" ? "desc" : "asc");
  }

  const { setActiveLayout } = useJumboApp();

  const { type } = useParams();

  React.useEffect(() => {
    setActiveLayout(LAYOUT_NAMES.VERTICAL_DEFAULT);
  }, []);

  React.useLayoutEffect(() => {
    fetchUsers();
    /* setTimeout(fetchUsers, loading ? 500 : 0); */
  }, [page, type, paramsOrder, order]);

  React.useEffect(() => {
    const statusSearch = searchParams.get("status");
    if (statusSearch)
      setParams({
        ...paramsOrder,
        status: statusSearch,
      });
    else setParams({});
  }, [type]);

  React.useLayoutEffect(() => {
    setPage(1);
  }, [type]);

  /* React.useLayoutEffect(() => {
        fetchUsers();
    }, [page]);

    React.useLayoutEffect(() => {
        if(page == 1) 
            fetchUsers();
        else
            setPage(1);
    }, [type]); */

  async function fetchUsers() {
    if (!continueFetching) return;

    setLoading(true);
    try {
      let params = {};

      if (paramsOrder.status != "Todos") {
        params = {
          status: paramsOrder.status,
        };
      }

      if (paramsOrder.s) {
        params["s"] = paramsOrder.s;
      }

      if (paramsOrder.orderBy) {
        params["orderBy"] = `${paramsOrder.orderBy},${order}`;
      }

      const { data } = await api.get(
        `/api/v2/dashboard/${
          type == "administradores" ? "usuarios" : type
        }?page=${page}`,
        {
          params,
          headers: {
            Authorization: `Bearer ${auth.token}`,
          },
        }
      );

      setUsers(/* type == "administradores" ? data.data.data : */ data.data);
      setTotalPages(
        /* type == "administradores" ? data.data.last_page : */ data.last_page
      );
    } catch (error) {
      console.log("ERROR > > > ", error);
    }
    setLoading(false);
    setContinueFetching(true);
  }

  const getLabel = () => {
    const labels = {
      clientes: "Clientes",
      tecnicos: "Técnicos",
      administradores: "Administradores",
    };

    return labels[type];
  };

  const getButtonLabel = () => {
    const labels = {
      clientes: "Adicionar cliente",
      tecnicos: "Novo técnico",
      administradores: "Adicionar usuário",
    };

    return labels[type];
  };

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row">
          <Typography variant={"h2"} mb={3}>
            Listagem de {getLabel()}
          </Typography>
        </Stack>

        <Stack
          flex={1}
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <FormControl sx={{ mr: 1, minWidth: 150 }} size="small">
            <InputLabel id="demo-select-small">Status</InputLabel>
            <Select
              labelId="demo-select-small"
              id="demo-select-small"
              defaultValue={searchParams.get("status")}
              value={paramsOrder.status}
              label="Status"
              onChange={(event) => {
                setParams({
                  ...paramsOrder,
                  status: event.target.value,
                });
              }}
            >
              <MenuItem value="Todos">Todos</MenuItem>
              <MenuItem value="Ativo">Ativo</MenuItem>
              <MenuItem
                sx={{ display: type == "tecnicos" ? "inherit" : "none" }}
                value="Pendente"
              >
                Pendentes
              </MenuItem>
              <MenuItem
                sx={{ display: type == "tecnicos" ? "inherit" : "none" }}
                value="Reprovado"
              >
                Reprovados
              </MenuItem>
              {/* {
                                type == "tecnicos" &&(
                                    <>
                                        <MenuItem value="Pendente">Pendentes</MenuItem>
                                        <MenuItem value="Reprovado">Reprovados</MenuItem>
                                    </>
                                )
                            } */}
              <MenuItem value="Desativado">Inativo</MenuItem>
            </Select>
          </FormControl>

          <Div sx={{ mr: 3 }}>
            <FormControl sx={{ mr: 1, minWidth: 150 }} size="small">
              <InputLabel id="demo-select-small">Ordenar por</InputLabel>
              <Select
                labelId="demo-select-small"
                id="demo-select-small"
                value={paramsOrder.orderBy}
                label="orderBy"
                onChange={(event) => {
                  setParams({
                    ...paramsOrder,
                    orderBy: event.target.value,
                  });
                }}
              >
                <MenuItem value="">
                  <em>Selecione...</em>
                </MenuItem>
                <MenuItem value="name">Nome</MenuItem>
                <MenuItem value="email">E-mail</MenuItem>
                <MenuItem
                  sx={{
                    display: type == "administradores" ? "inherit" : "none",
                  }}
                  value="permission"
                >
                  Permissão
                </MenuItem>
                <MenuItem
                  sx={{
                    display: type != "administradores" ? "inherit" : "none",
                  }}
                  value="cel"
                >
                  Telefone
                </MenuItem>
                <MenuItem
                  sx={{
                    display: type != "administradores" ? "inherit" : "none",
                  }}
                  value="empresa"
                >
                  Empresa
                </MenuItem>
                {/* {
                                    type == "administradores" ? (
                                        <MenuItem value="permission">Permissão</MenuItem>
                                    ) : (
                                        <>
                                            <MenuItem value="cel">Telefone</MenuItem>
                                            <MenuItem value="empresa">Empresa</MenuItem>
                                        </>
                                    )
                                } */}
                <MenuItem value="created_at">Data Registro</MenuItem>
              </Select>
            </FormControl>

            <Button variant="outlined" onClick={toggleOrder}>
              <SortIcon
                sx={{ transform: `rotateX(${order == "asc" ? 0 : 180}deg)` }}
              />
            </Button>
          </Div>

          <Button
            variant="contained"
            color="success"
            onClick={() => navigate(`/usuarios/${type}/cadastro`)}
          >
            {getButtonLabel()}
          </Button>

          <JumboSearch
            onChange={(e) => {
              setContinueFetching(false);
              setPage(1);
              setContinueFetching(true);
              setParams({
                ...paramsOrder,
                s: e,
              });
            }}
            placeholder="Procurar usuário..."
            sx={{
              display: { xs: "none", md: "block" },
              ml: 3,
            }}
          />
        </Stack>
      </Stack>

      {loading ? (
        <UsuariosSkeleton />
      ) : (
        <Stack style={{ marginTop: 10 }}>
          <UsersList data={users} type={type} />
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            shape="rounded"
            variant="outlined"
            color="secondary"
          />
        </Stack>
      )}
    </>
  );
};

export default Usuarios;
