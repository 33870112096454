import io from "socket.io-client";

let socketServer = "https://socket.mifix.dev.focalweb.com.br";
let chat = "https://chat.mifix.dev.focalweb.com.br";

switch (process.env.REACT_APP_ENV) {
  case "localhost": {
    socketServer = "https://socket.mifix.dev.focalweb.com.br";
    chat = "https://chat.mifix.dev.focalweb.com.br";
    break;
  }
  case "development": {
    socketServer = "https://socket.mifix.dev.focalweb.com.br";
    chat = "https://chat.mifix.dev.focalweb.com.br";
    break;
  }
  case "production": {
    socketServer = "https://socket.mifix.com.br";
    chat = "https://chat.mifix.com.br";
    break;
  }
}

export const socket = io(chat, { jsonp: false, transports: ["websocket"] });

export const socketTicket = io(socketServer, {
  jsonp: false,
  transports: ["websocket"],
});

// https://chat.mifix.com.br
// https://socket.mifix.com.br

//http://192.168.1.235:3001
