export function setLastSend() {
  return {
    type: "@mifix_lastSend",
  };
}

export function setRelatorio(value, excel) {
  return {
    type: "@mifix_relatorio",
    payload: {
      value,
      excel,
    },
  };
}
