import { Chip } from "@mui/material";
import useBiContext from "../../hooks/BiProvider/useBiContext";

const ChipStatus = ({ status }) => {
  const { statuses } = useBiContext();

  const statusObj = statuses.find((s) => s.name.includes(status)) || {
    name: "Desconhecido",
    color: "error",
  };

  return (
    <Chip
      label={statusObj.name}
      size="small"
      variant="filled"
      style={{ backgroundColor: statusObj.color, color: "white" }}
    />
  );
};

export default ChipStatus;
