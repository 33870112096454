import { Card, Stack } from "@mui/material";
import Div from "@jumbo/shared/Div";
import SearchForm from "./components/BiFilters/SearchForm";
import { Formik } from "formik";
import MapComponent from "./components/MapComponent";
import MapTechniciansList from "./components/BiTechniciansList/MapTechniciansList";
import { names } from "./components/mockData";

const MapaTecnicos = () => {
  return (
    <>
      <Formik
        initialValues={{
          empresa: "",
          cliente: "",
          matricula: "",
          segmento: "",
          status: [],
          regiao: "",
          cidade: "",
          tecnico: "",
          solicitante: "",
          data_inicio: "",
          data_final: "",
          perPage: 10,
        }}
        onSubmit={() => {}}
      >
        <>
          <Div>
            <SearchForm />
          </Div>
          <Card sx={{ width: "100%", justifyContent: "space-between" }}>
            <Stack direction="row" flex={1}>
              <Stack
                style={{
                  paddingTop: 30,
                  paddingBottom: 20,
                  paddingLeft: 30,
                  paddingRight: 20,
                  width: "60%",
                }}
              >
                <MapTechniciansList data={names} />
              </Stack>
              <MapComponent />
            </Stack>
          </Card>
        </>
      </Formik>
    </>
  );
};

export default MapaTecnicos;
