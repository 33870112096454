export const names = [
  {
    id: 40523,
    os: "5567041",
    equipamento_nome: "000a",
    empresa: "BENEFICENCIA PORTUGUESA",
    solicitante_name: "BackOffice",
    client: "teste",
    created_at: "2025-02-27 14:26:19",
    status: { id: 1, name: "Aberto" },
    recent: [
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 4,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 3,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 2,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 1,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
    ],
  },
  {
    id: 40524,
    os: "5567041",
    equipamento_nome: "000a",
    empresa: "teste",
    solicitante_name: "BackOffice",
    client: "teste",
    created_at: "2025-02-27 14:26:19",
    status: { id: 2, name: "A caminho" },
    recent: [
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 4,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 3,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 2,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 1,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
    ],
  },
  {
    id: 40525,
    os: "5567041",
    equipamento_nome: "000a",
    empresa: "teste",
    solicitante_name: "BackOffice",
    client: "teste",
    created_at: "2025-02-27 14:26:19",
    status: { id: 3, name: "Chegou" },
    recent: [
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 4,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 3,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 2,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 1,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
    ],
  },
  {
    id: 40526,
    os: "5567041",
    equipamento_nome: "000a",
    empresa: "teste",
    solicitante_name: "BackOffice",
    client: "teste",
    created_at: "2025-02-27 14:26:19",
    status: { id: 4, name: "Resolvido" },
    recent: [
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 4,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 3,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 2,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 1,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
    ],
  },
  {
    id: 40527,
    os: "5567041",
    equipamento_nome: "000a",
    empresa: "teste",
    solicitante_name: "BackOffice",
    client: "teste",
    created_at: "2025-02-27 14:26:19",
    status: { id: 5, name: "Pendente" },
    recent: [
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 4,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 3,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 2,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 1,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
    ],
  },
  {
    id: 40528,
    os: "5567041",
    equipamento_nome: "000a",
    empresa: "teste",
    solicitante_name: "BackOffice",
    client: "teste",
    created_at: "2025-02-27 14:26:19",
    status: { id: 6, name: "Orfão" },
    recent: [
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 4,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 3,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 2,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 1,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
      {
        id: 40578,
        os: "9512910",
        created_at: "2025-03-25 09:33:16",
        ticket_status_id: 5,
        data_de_resolucao: "2025-03-28 09:54:02",
        tecnico: null,
      },
    ],
  },
];
