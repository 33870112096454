import React from "react";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import { Card, Typography } from "@mui/material";
import styled from "@emotion/styled";
import Span from "@jumbo/shared/Span";
import { history } from "app/redux/store";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Item = styled(Span)(({ theme }) => ({
  padding: theme.spacing(0, 1),
}));

const UserItem = ({ user, type }) => {
  const navigate = useNavigate();
  return (
    <Card
      sx={{ mb: 1, cursor: "pointer" }}
      onClick={() =>
        navigate(`/usuarios/${type}/editar/${user.id || user.user_id}`)
      }
    >
      <Stack
        direction={"row"}
        justifyContent="space-between"
        alignItems={"center"}
        sx={{ p: (theme) => theme.spacing(2, 2) }}
      >
        <Item
          sx={{
            width: "300px",
          }}
        >
          <Stack direction={"row"} alignItems={"center"}>
            <Item>
              <Avatar
                sx={{
                  width: 56,
                  height: 56,
                }}
                alt={user.name}
                src={`https://storage.googleapis.com/uteky/images/tecnicos/${
                  user.id || user.user_id
                }_100x100.jpg?random=${Math.floor(Math.random() * 10000)}`}
              />
            </Item>
            <Item>
              <Typography variant={"h6"} mb={0.5}>
                Nome
              </Typography>
              <Typography variant={"body1"} color="text.secondary">
                {user.name}
              </Typography>
            </Item>
          </Stack>
        </Item>

        <Item sx={{ width: "250px" }}>
          <Typography variant={"h6"} mb={0.5}>
            E-mail
          </Typography>
          <Typography variant={"body1"} color="text.secondary">
            {user.email}
          </Typography>
        </Item>
        <Item sx={{ width: "250px" }}>
          <Typography variant={"body1"} color="text.secondary">
            Telefone
          </Typography>
          <Typography variant={"h6"} mb={0.5}>
            {user.cel || user.telefone || "N/A"}
          </Typography>
        </Item>
        <Item sx={{ width: "250px" }}>
          <Typography variant={"body1"} color="text.secondary">
            Empresa
          </Typography>
          <Typography variant={"h6"} mb={0.5}>
            {user.empresa || "N/A"}
          </Typography>
        </Item>
        <Item>
          <Typography variant={"body1"} color="text.secondary">
            Data Registro
          </Typography>
          <Typography variant={"h6"} mb={0.5}>
            {moment(user.created_at).format("DD/MM/YYYY")}
          </Typography>
        </Item>
      </Stack>
    </Card>
  );
};

export default UserItem;
