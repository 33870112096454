import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ActivitiesItem from "app/shared/widgets/RecentActivities/ActivitiesItem";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  maxHeight: "80vh",
  overflowY: "auto",
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  paddingLeft: 4,
  paddingRight: 4,
  paddingTop: 2,
  paddingBottom: 2,
  borderRadius: "8px",
};

const TechnicianModal = ({ open, onClose, item }) => {
  if (!item) return null;
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="technician-details-modal-title"
      aria-describedby="technician-details-modal-description"
    >
      <Box sx={modalStyle}>
        {/* Modal Header */}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mb={2}
        >
          <Typography
            id="technician-details-modal-title"
            variant="h6"
            component="h2"
          >
            Detalhes do Técnico #{item?.id}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <CloseIcon />
          </IconButton>
        </Box>

        {/* Modal Content */}
        <div>
          {item.recent && item.recent.length > 0 ? (
            item.recent.map((activity, index) => (
              <ActivitiesItem item={activity} key={index} />
            ))
          ) : (
            <Typography variant="body2" color="text.secondary">
              Sem atividades recentes disponíveis.
            </Typography>
          )}
        </div>
      </Box>
    </Modal>
  );
};

export default TechnicianModal;
