import React from "react";

const ContadoresTopo = () => {
  return (
    <div className="grid grid-cols-2 gap-2">
      <div className="flex flex-col flex-wrap p-2 rounded-md items-center justify-center cursor-pointer bg-primary text-sm text-white">
        <span className="text-2xl">1200</span>
        <span> Contagem de OS</span>
      </div>
      <div className="flex flex-col flex-wrap p-2 rounded-md items-center justify-center cursor-pointer bg-primary text-sm text-white">
        <span className="text-2xl">100</span>
        <span> Contagem de Status</span>
      </div>
    </div>
  );
};

export default ContadoresTopo;
