import { id } from "date-fns/locale";
import React, { useState } from "react";

export const BiContext = React.createContext(undefined);

const BiProvider = ({ children }) => {
  const [filterStatus, setFilterStatus] = useState([]);
  const statuses = [
    {
      id: 1,
      name: "Aberto",
      color: "#FFC107",
    },
    {
      id: 5,
      name: "Pendente",
      color: "#F44336",
    },
    {
      id: 2,
      name: "A caminho",
      color: "#FF5722",
    },
    {
      id: 3,
      name: "Chegou",
      color: "#4CAF50",
    },
    {
      id: 8,
      name: "Em garantia",
      color: "#2196F3",
    },
    {
      id: 7,
      name: "Cancelado",
      color: "#607DBD",
    },
    {
      id: 6,
      name: "Orfão",
      color: "#607D8B",
    },
    {
      id: 4,
      name: "Resolvido",
      color: "#4CAF50",
    },
  ];
  const values = { filterStatus, setFilterStatus, statuses };
  return <BiContext.Provider value={values}>{children}</BiContext.Provider>;
};

export default BiProvider;
