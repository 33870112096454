import React, { useEffect, useState } from "react";
import { useFormikContext } from "formik";
import TechnicianModal from "./TechnicianModal";
import MapTechnicianCard from "./MapTechnicianCard";
import { Pagination, Typography } from "@mui/material";
import { useBiSistemaQuery } from "../../hooks/bi-slice";

const MapTechniciansList = () => {
  const { values } = useFormikContext();
  const { data, isLoading, isFetching } = useBiSistemaQuery(values);

  // Modal
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  const handleOpenModal = (item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedItem(null);
  };

  // Pagination e contagem de tickets
  const [page, setPage] = useState(1);
  const itemsPerPage = 6;
  // const totalItems = data.length;

  // const startIndex = (page - 1) * itemsPerPage + 1;
  // const endIndex = Math.min(page * itemsPerPage, totalItems);

  // Console logs

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full">carregando</div>
    );
  }

  return (
    <>
      <div className="grid grid-cols-1 gap-2 mb-4">
        {data?.data.map((item, index) => (
          <MapTechnicianCard
            key={index}
            item={item}
            onClick={() => handleOpenModal(item)}
          />
        ))}
      </div>
      <div className="flex flex-row items-center justify-between w-full">
        <Pagination count={5} page={1} shape="rounded" size="small" />
        {/* Contagem de tickets */}
        <Typography variant="body2" color="text.secondary">
          <Typography variant="body2" color="text.secondary">
            Página{" "}
            <span style={{ color: "#475259", fontWeight: "500" }}>
              {" "}
              {page}{" "}
            </span>
            de
            <span style={{ color: "#475259", fontWeight: "500" }}>
              {" "}
              {data.last_page}
            </span>
          </Typography>
        </Typography>

        <TechnicianModal
          open={openModal}
          onClose={handleCloseModal}
          item={selectedItem}
        />
      </div>
    </>
  );
};

export default MapTechniciansList;
