import React, { useContext } from "react";
import { BiContext } from "./BiProvider";

const useBiContext = () => {
  const context = useContext(BiContext);
  if (!context) {
    throw new Error("Essa página deve ser usada dentro de um BI CONTEXT");
  }
  return context;
};

export default useBiContext;
