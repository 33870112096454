import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const baseApiSlice = createApi({
  reducerPath: "baseApiSlice",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API,
    prepareHeaders: (headers, { getState }) => {
      const { auth } = getState();
      if (!auth.token) return headers;
      headers.set("authorization", `Bearer ${auth.token}`);
      return headers;
    },
  }),

  endpoints(builder) {
    return {};
  },
});
